<template>
  <footer>
    <div class="bg-gris py-5 border-t-2 border-gris z-50">
      <div class="md:grid md:grid-cols-2 mx-[10%]">
        <div class="px-5 mx-auto pt-5 pb-10 md:pb-0">
          <div class="pb-5">
            <h1 class="text-2xl font-bold text-white">Nous suivre</h1>
            <hr class="w-36 h-1  bg-blue-base border-0 rounded">
          </div>

          <!-- Twitter links -->
          <div v-for="i in facebook" class=" pb-5 pl-5 flex items-center invert group">
            <img src="../../public/assets/images/instagram-1909748.svg" class="group-hover:hidden p-[4px]" style="width: 30px; height: 30px;">
            <a class="hover:text-gray-500 text-black" href="https://www.instagram.com/telecomnancyservices" target="_blank">
              <p class="ml-3 text-black">{{ i.name }}</p></a><br>
          </div>
          <!-- Facebook links -->
          <div v-for="i in facebook" class=" pb-5 pl-5 flex items-center invert group">
            <img src="../../public/assets/images/facebook.svg" class="group-hover:hidden p-[4px]" style="width: 30px; height: 30px;">
            <a class="hover:text-gray-500 text-black" :href="i.link" target="_blank">
              <p class="ml-3 text-black">{{ i.name }}</p></a><br>
          </div>

          <!-- Linkedin links -->
          <div v-for="i in linkedin" class=" pb-5 pl-5 flex items-center invert group">
            <img src="../../public/assets/images/linkedin.svg" class="group-hover:hidden p-[4px]" style="width: 30px; height: 30px;">
            <a class="hover:text-gray-500 text-black" :href="i.link" target="_blank">
              <p class="ml-3 text-black">{{ i.name }}</p></a><br>
          </div>
        </div>
        <div class="px-5 block">
          <div>
            <img alt="Logo" src="@/assets/tns_logo_complet_blanc_fond_transparent_300ppp.png" class="m-auto w-[40%]">
          </div>
          <div class="text-white text-center space-y-1">
            <div>
              <a class="hover:text-gray-500" href="https://www.google.fr/maps/place/TELECOM+Nancy/@48.6690814,6.1551443,20.39z/data=!4m9!1m2!2m1!1s193,+avenue+Paul+Muller+54602+Villers-l%C3%A8s-Nancy!3m5!1s0x4794a27b958c5c31:0xda674138b6da42e7!8m2!3d48.6691262!4d6.155318!15sCjAxOTMsIGF2ZW51ZSBQYXVsIE11bGxlciA1NDYwMiBWaWxsZXJzLWzDqHMtTmFuY3mSAQ9ncmFkdWF0ZV9zY2hvb2zgAQA"
                 target="_blank">
                <p>193, avenue Paul Muller 54602 Villers-lès-Nancy</p></a>
            </div>

            <div>
              <a class="hover:text-gray-500" href="tel:0778436881"><p>+33 (0)7 78 43 68 81</p></a>
            </div>

            <div>
              <a class="hover:text-gray-500" href="mailto:contact@tnservices.fr"><p>contact@tnservices.fr</p></a>
            </div>

            <div class="pt-2 font-bold">
              <p class="hover:text-gray-500">
                <router-link :to="{name: 'contact'}" class="router-link">Nous contacter</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center text-gray-300 pt-2">
        <p>© {{new Date().getFullYear()}} TNServices</p>
        <p>Made by <a class="hover:text-gray-500" href="https://www.linkedin.com/in/silvio-brancati-82970219b/">Silvio Brancati</a> - <a class="hover:text-gray-500" href="https://www.linkedin.com/in/anthony-briot-7521a3218/">Anthony Briot</a> - <a class="hover:text-gray-500" href="https://www.linkedin.com/in/mat%C3%A9o-demangeon-6962a4208/">Matéo Demangeon</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';
import twitterJ from '../../public/assets/images/twitter.json';
import facebookJ from '../../public/assets/images/facebook.json';
import linkedinJ from '../../public/assets/images/linkedin.json';
export default {
  name: 'Footer',
  components: {
    Vue3Lottie
  },
  data() {
    return {
      twitterJson: twitterJ,
      facebookJson: facebookJ,
      linkedinJson: linkedinJ,
      twitter: [
        {
          name: "@TNServices",
          link: "https://twitter.com/TelecomNS/",
        },
        {
          name: "@TELECOMNancy",
          link: "https://twitter.com/TELECOMNancy",
        }
      ],
      facebook: [
        {
          name: "Telecom Nancy Services",
          link: "https://www.facebook.com/tnservices/",
        },
      ],
      linkedin: [
        {
          name: "Telecom Nancy Services",
          link: "https://www.linkedin.com/company/telecom-nancy-services/",
        },
      ],

    }
  }
}
</script>

<style>

#show {
  display: none
}
#main:hover + #show {
  display: block
}
#main:hover ~ #hide {
  display: none
}
</style>